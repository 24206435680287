<template>
  <div>
    <NuxtLayout name="default" :is-primary-navbar-sticky="false">
      <!-- section-hero -->
      <HeroStandardApollo
        id="asuonline-students"
        :title="$page?.students.title!"
        :bg-image-source="$page?.students.heroImage!"
        :show-text="false"
        title-size="large"
        :display-gradient="true"
        :gradient-opacity="0.85"
        :full-width="false"
      ></HeroStandardApollo>
      <!-- video-modal section -->
      <section class="py-space-md py-md-space-lg py-lg-space-xxxl">
        <div class="container">
          <div class="row gx-0">
            <div class="col-12">
              <VideoModalAtlas
                :video-source="generateVideoEmbedUrl($page?.students?.video?.youtubeId!)"
                video-position="right"
                :bg-image-source="$page?.students?.video?.imageSource!"
                :title="$page?.students?.video?.title!"
                :display-border="false"
                @modal-open="
                  handleModalEvent(
                    'open',
                    $page?.students?.video?.title!,
                    'play button'
                  )
                "
                @modal-close="
                  handleModalEvent(
                    'close',
                    $page?.students?.video?.title!,
                    'close button'
                  )
                "
              >
                <div v-html="$page?.students?.video?.description"></div>
              </VideoModalAtlas>
            </div>
          </div>
        </div>
      </section>

      <!-- section-secondary-navbar -->
      <NavbarStickyAtlas
        :nav-items="navbarItems"
        :display-apply-now="false"
        :display-rfi-cta="false"
        item-desktop-text-size="small"
        underline-variant="primary"
        class="mb-space-md"
      ></NavbarStickyAtlas>

      <!-- features-as-steps: switch between apollo/atlas based on even/odd index -->
      <section v-for="(item, index) in $page?.students?.steps" :key="index">
        <SectionStepApollo
          :image-position="isEven(index) ? 'left' : 'right'"
          component-type="div"
          :id="generateAnchorId(item?.navItemTitle!)"
          :title="item?.title!"
          :image-source="item?.image!"
        >
          <template #circle>
            <div class="d-flex align-items-center h-100">
              <img
                :src="item?.icon!"
                class="students-feature-icon"
                :alt="item?.iconAlt!"
              />
            </div>
          </template>
          <div class="mb-space-sm" v-html="item?.text"></div>
          <NuxtLink
            :href="item?.cta?.uri!"
            class="btn btn-secondary fw-bold"
            @click="handleLinkClick(item?.title!, item?.cta?.text!)"
          >
            {{ item?.cta?.text }}
          </NuxtLink>
        </SectionStepApollo>
      </section>

      <!-- section-rfi -->
      <SectionRfiMultiStep
        class="mt-space-md"
        :bg-image="RfiBgImage"
      ></SectionRfiMultiStep>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import RfiBgImage from "~/assets/img/rfi-bg-overlay.png";
import SectionRfiMultiStep from "~/components/rfi/SectionRfiMultiStep.vue";
import { useUtilityService } from "~/composables/useUtilityService";
import { Students } from "~/server/resolvers/resolvers-types";

definePageMeta({
  layout: false,
  __dangerouslyDisableSanitizersByTagID: {
    "ldjson-schema": ["innerHTML"],
  },
});

const config = useRuntimeConfig();

const UtilityService = useUtilityService();

interface StudentsResponse {
  students: Students;
}

const $page = await usePageQuery<StudentsResponse>(
  `#graphql
      query students($studentsId: ID!){
        students(id:$studentsId) {
          detailPage
          metaTitle
          metaDescription
          heroImage
          title
          video {
            id
            title
            description
            youtubeId
            imageSource
            imageAlt
            uploadDate
            duration
            captionText
          }
          steps {
            id
            title
            text
            icon
            iconAlt
            image
            imageAlt
            navItemTitle
            cta {
              id
              text
              uri
              target
            }
          }
        }
      }`,
  {
    studentsId: "9a05683b-7617-4d09-ad9e-735094ec52ee",
  },
  {
    key: "students-home",
  }
);

useHead({
  title: $page?.students.metaTitle,
  meta: [
    { name: "Title", content: $page?.students.metaTitle },
    {
      name: "Description",
      content: $page?.students.metaDescription,
    },
  ],
  link: [
    {
      rel: "canonical",
      href: UtilityService.formatCanonicalUrl(
        `${config.public.siteUrl}${$page?.students.detailPage}`
      ),
    },
  ],
  script: [useSchemaService().generateStudentsSchema($page?.students)],
});

const handleModalEvent = (action: string, section: string, text: string) => {
  analyticsComposable.trackModalEvent(
    "onclick",
    action.toLowerCase(),
    "click",
    "main content",
    section.toLowerCase(),
    text.toLowerCase(),
    "video - modal"
  );
};

const handleLinkClick = (section: string, text: string) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    section.toLowerCase(),
    text.toLowerCase()
  );
};

const isEven = (number: number) => {
  return number % 2 === 0;
};

const generateAnchorId = (title: string) => {
  var finalTitle = title.replace(/[^a-zA-Z0-9 ]/g, "");
  return `${finalTitle.split(" ").join("-").toLowerCase()}`;
};

const generateVideoEmbedUrl = (id: string) => {
  return `https://www.youtube.com/embed/${id}`;
};

const navbarItems = computed(() => {
  interface NavItemsContent {
    [key: string]: string;
  }

  let navbarItems: NavItemsContent = {};

  navbarItems["asuonline-students"] = "Back to top";
  $page?.students.steps!.forEach((item) => {
    navbarItems[
      generateAnchorId(item?.navItemTitle!)
    ] = `${item?.navItemTitle}`;
  });

  return navbarItems;
});
</script>

<style lang="scss" scoped>
:deep(.step-circle) {
  display: flex;
  justify-content: center;
}
:deep(.step-number) {
  font-size: 0px;
  margin-top: 0 !important;
}

img.students-feature-icon {
  width: 27px;
  height: 35px;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  :deep(.step-circle) {
    display: flex;
    justify-content: center;
  }
  :deep(.step-number) {
    font-size: 0px;
    margin-top: 0 !important;
  }

  img.students-feature-icon {
    width: 52px;
    height: 52px;
  }
}
</style>
